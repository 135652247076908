import { Button, styled } from '@mui/material';

export const MTFButton = styled(Button, {
  name: 'Title',
  shouldForwardProp: prop => prop !== 'top' && prop !== 'bot',
})<{ top?: number; bot?: number }>(({ theme, top, bot }) => ({
  fontSize: '14px',
  lineHeight: 1.5,
  fontWeight: 500,
  marginTop: top ? `${top}px` : undefined,
  marginBottom: bot ? `${bot}px` : undefined,
}));
