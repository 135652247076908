import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

type StyleDict = Record<string, React.CSSProperties | string>;

function globalCss(global: StyleDict, theme: Theme) {
  // keep line break for help text

  global['.MuiButtonBase-root.MuiButton-contained'] = {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.accent.light3,
    textTransform: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
  };
  global['.MuiButtonBase-root.MuiButton-contained:hover'] = {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.accent.light3,
    textTransform: 'none',
    padding: '8px 16px',
    borderRadius: '4px',
  };
  global['.MuiInputBase-root.MuiFilledInput-root'] = {
    backgroundColor: theme.palette.neutral.light3,
    border: `1px solid ${theme.palette.neutral.light2}`,
    borderBottom: 'none',
    height: '48px',
  };
  global['.MuiInputBase-root.MuiFilledInput-root:hover'] = {
    backgroundColor: theme.palette.neutral.light2,
  };
  global['.MuiInputBase-root.MuiFilledInput-root.Mui-focused'] = {
    backgroundColor: theme.palette.primary.light3,
  };
  global['.MuiInputBase-root.MuiFilledInput-root::before'] = {
    borderBottom: `2px solid ${theme.palette.neutral.light1}`,
    transformOrigin: 'left',
  };

  global['.MuiInputBase-root.MuiFilledInput-root::after'] = {
    transformOrigin: 'left',
  };

  global[
    '.MuiInputBase-root.MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before'
  ] = {
    borderBottom: `2px solid ${theme.palette.neutral.dark1}`,
  };

  global['.MuiInputLabel-root.Mui-focused'] = {
    color: `${theme.palette.primary.dark2} !important`,
  };
  global['.MuiInputLabel-root:not(.Mui-focused, .MuiInputLabel-shrink)'] = {
    color: `${theme.palette.neutral.dark1}`,
  };
}

const useGlobalStyles = makeStyles((theme: Theme) => {
  const global: StyleDict = {
    '.srOnly': { display: 'none' },
    '.overflowHidden': { overflow: 'hidden' },
  };

  [globalCss].forEach(x => x(global, theme));

  return createStyles({ '@global': global });
});

export default useGlobalStyles;
