import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {}
  interface PaletteOptions {
    neutral: Record<string, any>;
    accent: Record<string, any>;
    danger: Record<string, any>;
    positive: Record<string, any>;
  }

  interface Palette {
    neutral: Record<string, any>;
    accent: Record<string, any>;
    danger: Record<string, any>;
    positive: Record<string, any>;
  }

  interface PaletteColorOptions {
    dark3: string;
    dark2: string;
    dark1: string;
    main: string;
    light1: string;
    light2: string;
    light3: string;
  }
  interface PaletteColor {
    dark3: string;
    dark2: string;
    dark1: string;
    main: string;
    light1: string;
    light2: string;
    light3: string;
  }
  interface FontFamily {
    Montserrat: 'Montserrat';
    OpenSans: 'Open Sans';
  }
}

// A custom theme for this app
const theme = createTheme({
  mixins: {},

  typography: {
    fontFamily: ['Montserrat', 'Open Sans'].join(','),
    htmlFontSize: 16, // base unit to calculate "rem"
    fontSize: 16, // default fontSize
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: 1.25,
      letterSpacing: '0',
    },
    h2: {
      fontWeight: '500',
      fontSize: '32px',
      lineHeight: 1.25,
      letterSpacing: '0',
    },
    h3: {
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: 1.25,
      letterSpacing: '0',
    },
    h4: {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: 24 / 18,
      letterSpacing: '0',
    },
    h5: {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: 20 / 15,
      letterSpacing: '0',
    },
    h6: {
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: 17 / 13,
      letterSpacing: '0',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '16',
      lineHeight: 1.5,
      letterSpacing: '0',
    },
  },
  palette: {
    neutral: {
      dark3: '#29292B',
      dark2: '#585C61',
      dark1: '#888F97',
      main: '#B7C2CD',
      light1: '#CCD4DC',
      light2: '#E0E5EB',
      light3: '#F5F7FA',
    },
    primary: {
      dark3: '#00223D',
      dark2: '#0E5078',
      dark1: '#1D7FB3',
      main: '#48B1E5',
      light1: '#94D1F0',
      light2: '#C7E9F9',
      light3: '#EEF7FC',
    },
    accent: {
      dark3: '#36021C',
      dark2: '#71142A',
      dark1: '#AB2738',
      main: '#E63946',
      light1: '#EE757E',
      light2: '#F5B0B6',
      light3: '#FFE5EF',
    },
    danger: {
      dark3: '#380609',
      dark2: '#742725',
      dark1: '#B04742',
      main: '#EC685E',
      light1: '#F08D81',
      light2: '#F6BCB8',
      light3: '#FFE5EF',
    },
    warning: {
      dark3: '#302705',
      dark2: '#706229',
      dark1: '#B19E4C',
      main: '#F1D970',
      light1: '#F6E5A2',
      light2: '#F9EFC3',
      light3: '#FDFAEC',
    },
    positive: {
      dark3: '#052E11',
      dark2: '#286F3D',
      dark1: '#4BB069',
      main: '#6EF195',
      light1: '#9AF5B5',
      light2: '#C7F8D4',
      light3: '#F3FCF4',
    },
  },
  breakpoints: {
    values: {
      xs: 375,
      sm: 540,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body {
        }
        
      `,
    },
  },
});

export default theme;
