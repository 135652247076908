import { styled } from '@mui/material';

export const Main = styled('div', {
  name: 'Main',
  shouldForwardProp: prop => prop !== 'isWelcomePage',
})<{ isWelcomePage?: boolean }>(({ theme, isWelcomePage }) => ({
  minWidth: '100px',
  ...(!isWelcomePage
    ? {
        marginLeft: '400px',
        transition: 'all 0.3s ease-in-out',
        padding: '64px 168px'
      }
    : {
        marginLeft: '705px',
        transition: 'all 0.3s ease-in-out',
        padding: '64px'
      })
}));

export const Divider = styled('div', { name: 'Divider' })<{ height?: string }>(
  ({ theme, height }) => ({
    backgroundColor: theme.palette.neutral.light2,
    height: 'auto',
    alignItems: 'stretch',
    width: '1px',
    display: 'block',
    fontSize: '0',
    lineHeight: '1px',
    visibility: 'visible',
    borderBottom: '1px solid #d9d9d9',
    overflow: 'hidden',
    margin: '0px 32px'
  })
);

export const Header = styled('div', { name: 'Header' })(({ theme }) => ({
  display: 'flex',
  paddingBottom: '32px',
  borderBottom: `1px solid ${theme.palette.neutral.light2}`
}));
