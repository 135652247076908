import { MTFButton } from '@common/components/Button';
import DirectLink from '@common/components/DirectLink';
import { BodyText, Title } from '@common/components/Typography';
import { TextField, styled } from '@mui/material';

const NameRow = styled('div', { name: 'NameRow' })(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns:
    'calc((100% - 48px) / 3) calc((100% - 48px) / 3) calc((100% - 48px) / 3)',
  gridGap: '24px',
}));

const FormWrapper = styled('div', { name: 'Row' })(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  marginBottom: '32px',
}));

const IdentityCheckConfirmPage = () => {
  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Identity Check</Title>
      <BodyText sx={{ mb: 3 }}>
        Please take some time to confirm the details below are correct. If you
        need to make any changes, you can do so now.
      </BodyText>
      <FormWrapper>
        <NameRow>
          <TextField id="filled-basic" label="First Name" variant="filled" />
          <TextField id="filled-basic" label="Middle Name" variant="filled" />
          <TextField id="filled-basic" label="Last Name" variant="filled" />
        </NameRow>
        <TextField
          fullWidth
          id="filled-basic"
          label="Date of Birth"
          variant="filled"
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="Issuing Country"
          variant="filled"
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="License Number"
          variant="filled"
        />
      </FormWrapper>
      <DirectLink to="/identity-check">
        <MTFButton variant="contained">Confirm and Continue</MTFButton>
      </DirectLink>
    </>
  );
};

export default IdentityCheckConfirmPage;
