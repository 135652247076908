import { styled } from '@mui/material';

export const Title = styled('div', {
  name: 'Title',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.h1.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  lineHeight: theme.typography.h1.lineHeight,
}));

export const SubTitle = styled('div', {
  name: 'SubTitle',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  lineHeight: theme.typography.h2.lineHeight,
}));

export const BodyText = styled('div', {
  name: 'BodyText',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  lineHeight: theme.typography.body1.lineHeight,
}));

export const BodyText2 = styled('div', {
  name: 'BodyText',
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '500',
  lineHeight: theme.typography.body1.lineHeight,
  fontFamily: 'Open Sans !important'
}));

export const BodyText3 = styled('div', {
  name: 'BodyText'
})<{ top?: number; bot?: number }>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: '400',
  lineHeight: theme.typography.body1.lineHeight,
  fontFamily: 'Open Sans !important',
  color: '#29292B'
}));
