import { MTFButton } from '@common/components/Button';
import DirectLink from '@common/components/DirectLink';
import { BodyText, BodyText3, Title } from '@common/components/Typography';
import { Box } from '@mui/material';

const IdentityCheckPage = () => {
  return (
    <>
      <Title sx={{ mt: 4, mb: 2 }}>Identity Check</Title>
      <BodyText sx={{ mb: 4 }}>
        First, we have to verify your identity. You will be required to upload
        your valid driver's license. A facial verification process will then be
        conducted to confirm your identity.
      </BodyText>
      <BodyText sx={{ mb: 2 }}>
        <b>Required</b>
      </BodyText>
      <Box sx={{}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src="/assets/identification-card.svg" alt="" />
          <BodyText3 sx={{ ml: 2 }}>Driving license</BodyText3>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 4 }}>
          <img src="/assets/scan-smiley.svg" alt="" />
          <BodyText3 sx={{ ml: 2 }}>Facial verification</BodyText3>
        </Box>
      </Box>

      <DirectLink to="/identity-check/confirm">
        <MTFButton variant="contained">Start Verification</MTFButton>
      </DirectLink>
    </>
  );
};

export default IdentityCheckPage;
