import {
  ClientDetail,
  WelcomePage,
  IdentityCheckPage,
  IdentityCheckConfirmPage,
} from './modules';
import { createBrowserRouter } from 'react-router-dom';
import { UserLayout } from '@common/layouts';

const router = createBrowserRouter([
  {
    path: '/',
    element: <UserLayout />,
    children: [
      {
        path: '/',
        element: <WelcomePage />,
      },
      {
        path: '/welcome',
        element: <WelcomePage />,
      },
      {
        path: '/identity-check',
        element: <IdentityCheckPage />,
      },
      {
        path: '/identity-check/confirm',
        element: <IdentityCheckConfirmPage />,
      },
      {
        path: '/flying-licence',
        element: <ClientDetail />,
      },
      {
        path: '/medical-document',
        element: <ClientDetail />,
      },
      {
        path: '/trip-sheet',
        element: <ClientDetail />,
      },
    ],
  },
]);

export default router;
